import React from 'react'
import { List, Card, Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby'
import FeatureBox from '../../../components/box-image/layout-three'
import SwiperSlider from '../../../components/ui/swiper'
import GridLine from '../../../components/grid-line'
import { SectionWrap } from './features-area.stc';
import { 
    Tag
} from '../../../components/box-image/layout-three/box-image.style';
import {
    CategoryWrap
} from '../../../components/box-large-image/layout-two/box-large-image.style'

const listPagination = {
  simple: true, 
  position: "bottom",
  defaultPageSize: 4
}



const FeaturesArea = ({ slider, sliderStyle }) => {
    const featuresData = useStaticQuery(graphql`
        query ServicesFeaturesQuery {            
            cities : allAirtable(
                filter: {
                  table: { eq: "Cities" }
                }
              ) {
                edges {
                  node {
                    data {                      
                      Name
                      Slug
                      Note
                      Location : Region
                      Country
                      Photo {
                          url
                      }
                    }
                  }
                }
            }
            trips: allAirtable(
                filter: {
                  table: { eq: "Trips" }
                }
              ) {
                edges {
                  node {
                    data {                      
                      Title
                      Slug
                      TripBlurb
                      TripCity
                      TripCTA
                      Photo{
                          url
                      }
                      Period
                      DestinationAirportCode
                    }
                  }
                }
            } 
        }
    `)
    //const features = featuresData.allItSolutionsJson.edges
    const trips = featuresData.trips.edges
    const cities = featuresData.cities.edges
    return (
        <SectionWrap>
            <h3>Your Journey Awaits</h3>
            <List
                grid={{
                  gutter: 35,
                  xs: 1,
                  sm: 2,
                  md: 4,
                  lg: 4,
                  xl: 4,
                  xxl: 4,
                }}
                pagination={listPagination}
                className="mt-3 mt-lg-5"
                dataSource={trips}
                renderItem={trip => (
                  <List.Item>
                    <Card className="feature-item" style={{backgroundImage:`url(${trip.node.data.Photo[0].url})`}}>
                        <div>
                        <CategoryWrap className="category-wrap">{trip.node.data.Period && <Tag background="#12B077" color="#fff" ml="5px">{trip.node.data.Period} Days</Tag>}</CategoryWrap>
                        <FeatureBox
                            title={trip.node.data.Title}
                            desc={trip.node.data.TripBlurb}
                            category={trip.node.data.TripCity}
                            tag={trip.node.data.Period + ' days'}
                            imageSrc={trip.node.data.Photo[0].url}
                            path={`/trip/${trip.node.data.Slug}`}
                            cta="Explore Now"
                        />
                        </div>
                    </Card>
                  </List.Item>
                )}
            />   
            <h3>Find Your New Favorite City</h3>
            <List
                grid={{
                  gutter: 42,
                  xs: 1,
                  sm: 2,
                  md: 4,
                  lg: 4,
                  xl: 4,
                  xxl: 4,
                }}
                pagination={listPagination}
                style={{marginTop:'40px'}}
                dataSource={cities}
                renderItem={city => (
                  <List.Item>
                    <Card className="feature-item" style={{backgroundImage:`url(${city.node.data.Photo[0].url})`}}>
                        <div>
                        <CategoryWrap className="category-wrap">{city.node.data.Country && <div> <Tag background="#fff" color="#2a1b1b" ml="5px">{city.node.data.Country}</Tag> <Tag background="#2a1b1b" color="#fff" ml="5px">{city.node.data.Location}</Tag> </div>}</CategoryWrap>
                        <FeatureBox
                            title={city.node.data.Name}
                            desc={city.node.data.Note}                            
                            imageSrc={city.node.data.Photo[0].url}
                            cta="View City"
                            path={`/city/${city.node.data.Slug}`}
                        />
                        </div>
                    </Card>
                  </List.Item>
                )}
            />   
        </SectionWrap>
    )
}

FeaturesArea.propTypes = {
    slider: PropTypes.object,
    sliderStyle: PropTypes.object
}

FeaturesArea.defaultProps = {
    slider: {
        slidesPerView: 4,
        lazy: true,
        loop:true,
        autoplay: {
            delay: 0,
            disableOnInteraction: true
        },
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            650: {
                slidesPerView: 2
            },
            992: {
                slidesPerView: 3
            },
            1500: {
                slidesPerView: 4
            }
        }
    },
    sliderStyle: {        
        pagination: {
            mt: "30px",
            layout: "2"
        }
    }
}

export default FeaturesArea
